import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPatient } from "../../api/requests/patientRequests";
import avatar from "../../assets/images/undraw_Female_avatar_efig.png";
import Card from "../../components/admin/cards/Card";
import { roles, maritalStatus, education } from "../../assets/arrays/roles";

const Patient = () => {
  const user = useSelector((state) => state.user.user);
  const [patient, setPatient] = useState("Идет загрузка...");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await getPatient().then((data) => {
        const user = data.data.user;
        setPatient(
          <div className="p-4 gap-2">
            <div className="w-full p-6 bg-green-500 rounded-md text-white">
              Добро пожаловать в систему, {user.patient.firstName}{" "}
              {user.patient.lastName}!
            </div>
            <div>
              <img src={avatar} />
            </div>
            <div className="space-y-2">
              <Card header={"Данные пользователя"}>
                <div className="grid grid-cols-2 gap-2 border p-2 rounded-md">
                  <div>Логин</div>
                  <div>{user.username}</div>
                  <div>В системе:</div>
                  <div>{user.createdAt}</div>
                  <div>Роль:</div>
                  <div>
                    {roles.find(({ value }) => value === user.role).text}
                  </div>
                </div>
              </Card>
              <Card header={"Личные данные"}>
                <div className="grid grid-cols-2 gap-2 border p-2 rounded-md">
                  <div>Имя</div>
                  <div>{user.patient.firstName}</div>
                  <div>Фамилия:</div>
                  <div>{user.patient.lastName}</div>
                  <div>Отчество:</div>
                  <div>{user.patient.middleName}</div>
                  <div>Дата рождения:</div>
                  <div>{user.patient.DOB}</div>
                  <div>Пол:</div>
                  <div>
                    {user.patient.gender === "female" ? "Женский" : "Мужской"}
                  </div>

                  <div>Образование:</div>
                  <div>
                    {
                      education.find(
                        ({ value }) => value === user.patient.education
                      ).text
                    }
                  </div>
                </div>
              </Card>
              <Card header={"Семейное положение"}>
                <div className="grid grid-cols-2 gap-2 border p-2 rounded-md">
                  <div>Семейное положение:</div>
                  <div>
                    {
                      maritalStatus.find(
                        ({ value }) => value === user.patient.maritalStatus
                      ).text
                    }
                  </div>
                  <div>Количество детей:</div>
                  <div>{user.patient.numberOfKids}</div>
                </div>
              </Card>
              <Card header={"Место проживания и работы"}>
                <div className="grid grid-cols-2 gap-2 border p-2 rounded-md">
                  <div>Область:</div>
                  <div>{user.patient.region.nameRU}</div>
                  <div>Город/Район:</div>
                  <div>{user.patient.city.nameRU}</div>
                  <div>Адрес:</div>
                  <div className="flex-wrap">{user.patient.address}</div>
                  <div>Номер телефона:</div>
                  <div>{user.patient.phoneNumber}</div>
                  <div>Место работы:</div>
                  <div>{user.patient.placeOfWork}</div>
                  <div>Позиция:</div>
                  <div>{user.patient.position}</div>
                </div>
              </Card>
            </div>
            <div className="flex space-x-2">
              <Link to="/patient/history" replace>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                  />
                </svg>
              </Link>
              <Link to="/home/logout" replace>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                  />
                </svg>
              </Link>
            </div>
          </div>
        );
      });
    })();
  }, {});

  return <div>{patient}</div>;
};

export default Patient;
