import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PatientProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const [allowed, setAllowed] = useState(
    <div className="absolute left-0 top-0 bg-black bg-opacity-50 w-screen h-screen"></div>
  );

  useEffect(() => {
    if (user.role === "PATIENT") {
      navigate("/patient", { replace: true });
    } else {
      setAllowed(children);
    }
  }, []);
  return allowed;
};

export default PatientProtectedRoute;
