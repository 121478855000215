import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../components/admin/Header";
import LeftMenu from "../../components/admin/LeftMenu";
import { useSelector } from "react-redux";

const AdminLayout = () => {
  const user = useSelector((state) => state.user.user);
  return (
    <div className="bg-gray-50">
      <Header />
      <div className="flex flex-row">
        <LeftMenu />
        <div className="ml-60 p-2 w-full h-screen">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
