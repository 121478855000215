import "./App.css";
import Welcome from "./pages/Welcome";
import About from "./pages/About";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import AdminLayout from "./pages/admin/AdminLayout";
import Admin from "./pages/admin/pages/Admin";
import NothingFound from "./pages/NothingFound";
import Logout from "./components/admin/Logout";
import ProtectedRoute from "./components/admin/ProtectedRoute";
import UserInformation from "./pages/admin/pages/UserInformation";
import AddEditUserInfo from "./pages/admin/pages/AddEditUserInfo";
import RegionHealthOrgs from "./pages/admin/pages/RegionHealthOrgs";
import RegionHealthOrgDetails from "./pages/admin/pages/RegionHealthOrgDetails";
import TownHealthOrgs from "./pages/admin/pages/TownHealthOrgs";
import TownHealthCareOrgDetails from "./pages/admin/pages/TownHealthCareOrgDetails";
import AddEditHospital from "./pages/admin/pages/AddEditHospital";
import Registration from "./pages/Registration";
import AddEditUser from "./pages/admin/pages/AddEditUser";
import AddEditPatient from "./pages/admin/pages/AddEditPatient";
import ListOfPatients from "./pages/admin/pages/ListOfPatients";
import PatientView from "./pages/admin/pages/PatientView.jsx";
import AddEditContrType from "./pages/admin/pages/AddEditContrType.jsx";
import ListOfContr from "./pages/admin/pages/ListOfContr.jsx";
import ListOfHospitals from "./pages/admin/pages/ListOfHospitals.jsx";
import ListOfPersonnel from "./pages/admin/pages/ListOfPersonnel.jsx";
import AboutConts from "./pages/AboutConts.jsx";
import PatientLayout from "./pages/patients/PatientLayout.jsx";
import PatientProtectedRoute from "./components/admin/PatientProtectedRoute.jsx";
import Patient from "./pages/patients/Patient.jsx";
import History from "./pages/patients/History.jsx";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Welcome />} />
          <Route path="about" element={<About />} />
          <Route path="contr" element={<AboutConts />} />
          <Route path="registration" element={<Registration />} />
        </Route>

        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <PatientProtectedRoute>
                <AdminLayout />
              </PatientProtectedRoute>
            </ProtectedRoute>
          }
        >
          <Route index element={<Admin />} />
          <Route path="logout" element={<Logout />} />
          <Route path="user">
            <Route path="create" element={<AddEditUser />} />
            <Route path="list" element={<ListOfPersonnel />} />

            <Route path="information">
              <Route index element={<UserInformation />} />
              <Route path="edit" element={<AddEditUserInfo />} />
            </Route>
          </Route>

          <Route path="healthcare">
            <Route path="region">
              <Route index element={<RegionHealthOrgs />} />
              <Route path=":id" element={<RegionHealthOrgDetails />} />
            </Route>
            <Route path="town">
              <Route index element={<TownHealthOrgs />} />
              <Route path=":id" element={<TownHealthCareOrgDetails />} />
            </Route>
            <Route path="hospital">
              <Route index element={<ListOfHospitals />} />
              <Route path="addedit" element={<AddEditHospital />} />
            </Route>
            <Route path="patient">
              <Route path="addedit" element={<AddEditPatient />} />
              <Route path="list" element={<ListOfPatients />} />
              <Route path=":id" element={<PatientView />} />
            </Route>
            <Route path="contr">
              <Route path="addtype" element={<AddEditContrType />} />
              <Route path="list" element={<ListOfContr />} />
            </Route>
          </Route>

          <Route path="*" element={<NothingFound />} />
        </Route>

        <Route
          path="/patient"
          element={
            <ProtectedRoute>
              <PatientLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Patient />} />
          <Route path="history" element={<History />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
