import React, { useEffect, useState } from "react";
import { getLastContrs } from "../../api/requests/healthcareRequest";
import { useSelector } from "react-redux";
import DataTableView from "../../components/admin/DataTableView";
import DetailedHistoryOfWimex from "../../components/admin/patients/DetailedHistoryOfWimex";
import Card from "../../components/admin/cards/Card";
import Modal from "../../components/modal/Modal";
import LeftActionMenu from "../../components/admin/cards/LeftActionMenu";
import { Link } from "react-router-dom";

const History = () => {
  const user = useSelector((state) => state.user.user);
  const [modal2, setModal2] = useState(false);
  const [content, setContent] = useState("ИДёт загрузка");
  const [currWimex, setCurrWimex] = useState();

  const onRowClicked = (wimex) => {
    setModal2(!modal2);
    setCurrWimex(wimex);
  };

  const actionMenu = (
    <LeftActionMenu>
      <div className="flex flex-row">
        <div>
          <Link to="/patient" replace>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </Link>
        </div>
      </div>
    </LeftActionMenu>
  );
  useEffect(async () => {
    getLastContrs({ userId: user.id, last: 100 })
      .then((res) => {
        if (res.data.data.length > 0) {
          setContent(
            <div className="overflow-scroll">
              <DataTableView
                onRowClicked={onRowClicked}
                headers={["Наименование", "Тип", "Номер ордера", "Доп.Инфо"]}
                data={res.data.data}
                showOnly={[
                  "contraceptive.name",
                  "type",
                  "orderNumber",
                  "comments",
                ]}
              ></DataTableView>
            </div>
          );
        } else {
          setContent(
            <div className="h-48 w-f text-center font-bold my-auto">
              Ничего не найдено
            </div>
          );
        }
      })
      .catch((e) => {});
  }, []);

  return (
    <div className="p-2">
      <Modal showModal={modal2} setShowModal={setModal2}>
        <DetailedHistoryOfWimex wimex={currWimex} setModal={setModal2} />
      </Modal>

      <Card
        header={"История об использовании контрацептивов"}
        actionMenu={actionMenu}
      >
        {content}
      </Card>
    </div>
  );
};

export default History;
