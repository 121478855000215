import React from "react";
import NavigationLink from "./NavigationLink";
import { mainLinks } from "../links/mainlinks";
import MenuLink from "../links/MenuLink";
import { useNavigate } from "react-router-dom";

const ModalMenu = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`fixed ${
        showModal ? `visible` : `hidden`
      } flex bg-black bg-opacity-80 top-0 left-0 w-full h-full z-50 justify-end`}
      onClick={() => {
        setShowModal(false);
      }}
    >
      <div className="bg-white">
        <ul className="space-y-6 w-full">
          {mainLinks.map((item) => {
            return (
              <li
                key={item.name}
                className="p-6 text-indigo-500 font-bold font-mono uppercase 
        hover:text-white hover:bg-indigo-500 cursor-pointer"
                onClick={() => {
                  navigate(item.to, { replace: true });
                }}
              >
                <div className="flex flex-row justify-between space-x-1">
                  {item.icon}
                  {item.name}
                </div>
              </li>
            );
          })}
          {/* <li
            className="p-6 text-indigo-500 font-bold font-mono uppercase 
        hover:text-white hover:bg-indigo-500 cursor-pointer"
          >
            <div className="flex flex-row justify-between space-x-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                />
              </svg>
              Войти в систему
            </div>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default ModalMenu;
