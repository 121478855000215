import React, { useEffect } from "react";
import { useState } from "react";
import Login from "../forms/Login";
import Modal from "../modal/Modal";
import { useDispatch } from "react-redux";
import { user as setUser } from "../../store/user_slice";
import IconLink from "../links/IconLink";
import { getUser } from "../../api/apiRequests";

const LoginBtn = () => {
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState(
    <button
      className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 
                  focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 
                  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      onClick={() => setModal(!modal)}
    >
      <div className="flex my-auto space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
          />
        </svg>
        <div className=" hidden sm:block visible">Войти в систему</div>
      </div>
    </button>
  );
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const user = await getUser();
      if (user != null) {
        await dispatch(setUser(user));
        setContent(
          <IconLink
            to="/home"
            icon={
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="orange"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            }
            text={user.username}
          />
        );
      }
    })();
  }, []);

  return (
    <>
      <Modal showModal={modal} setShowModal={setModal}>
        <Login />
      </Modal>
      {content}
    </>
  );
};

export default LoginBtn;
