import React, { useEffect, useState } from "react";
import NavigationLink from "./NavigationLink";
import { mainLinks } from "../links/mainlinks";
import LoginBtn from "../buttons/LoginBtn";
import logo from "../../assets/images/baby.webp";
import ModalMenu from "./ModalMenu";

const Navigation = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ModalMenu showModal={showModal} setShowModal={setShowModal} />
      <div className="flex w-auto">
        <div className="flex lg:justify-around justify-evenly w-full">
          <div>
            <img src={logo} width={60} />
          </div>

          <nav
            className={`cursor-pointer nav font-semibold text-lg lg:opacity-0  my-auto shadow`}
          >
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setShowModal(true)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </nav>

          <nav className="nav font-semibold text-lg lg:block hidden">
            <ul className="flex items-center">
              {mainLinks.map((item) => {
                return (
                  <li key={item.name}>
                    <NavigationLink
                      key={item.name}
                      to={item.to}
                      name={item.name}
                    />
                  </li>
                );
              })}
            </ul>
          </nav>
          <div className="flex-none">
            <LoginBtn />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
