import React from "react";
import DefaultButton from "../components/buttons/DefaultButton";
import medics from "../assets/images/undraw_medical_care_movn.png";
import medics2 from "../assets/images/undraw_medicine_b1ol.png";

const Welcome = () => {
  return (
    <div className="bg-gray-100">
      <div
        className="flex 
      flex-row 
      justify-around 
      bg-welcome-image 
      w-auto bg-cover 
      bg-left-bottom 
      bg-no-repeat 
      top-0
      "
      >
        <div
          className="flex 
        flex-col 
        md:text-6xl text-3xl
        flex-1 
        py-20 pr-10 
        text-end 
        text-fuchsia-600 
        stroke-black stroke-2 "
        >
          <div>Электронная база женщин</div>
          <div>фертильного возраста</div>
          <div>
            <DefaultButton text={"Узнать больше"} />
          </div>
        </div>
        <div className="flex-1"></div>
      </div>

      <div className="p-16 my-5">
        <div className="flex md:flex-row flex-col  space-x-8 border border-gray-200 bg-white rounded-lg justify-center">
          <div className="basis-1/2 m-auto border-gray-200">
            <img src={medics} />
          </div>
          <div className="basis-1/2 flex-row space-y-7 text-justify m-auto p-12">
            <p className="uppercase font-bold">Что такое Контрацепция?</p>
            <p>
              Контрацепция - это не только способ предупреждения нежелательной
              беременности, но и способ сохранения репродуктивного здоровья.
              Разберемся, какие методы контрацепции существуют и рассмотрим
              каждый из них.
            </p>
            <p>
              Есть ли какой-то один универсальный метод контрацепции, который
              подходил бы всем, был удобным и защищал на 100%? К сожалению, нет.
              Существуют много разных методов контрацепции, у каждого есть свои
              плюсы, минусы и противопоказания. Никто не спорит, что нежеланную
              беременность лучше предупредить, чем ее прерывать и получить в
              будущем осложнения. Вреда от аборта намного больше, нежели от
              использования контрацептивов.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="p-16 my-5">
        <div className="flex md:flex-row flex-col  space-x-8 border border-gray-200 bg-white rounded-lg justify-center">
          <div className="basis-1/2 flex-row space-y-7 text-justify m-auto p-12">
            <p className="uppercase font-bold">Виды контрацепции</p>
            <p>
              Как же выбрать метод контрацепции? Это надо сделать совместно с
              гинекологом и подход должен быть индивидуальным. Необходимо учесть
              пожелания и предпочтения полового партнёра, так как любой метод
              контрацепции должен быть «удобным» для обоих, понятным в
              использовании, максимально эффективным и минимально «вредным» для
              организма женщины или мужчины, а также соответствовать стилю жизни
              половых партнеров.
            </p>
            <p>
              <b>Виды контрацепции:</b>
            </p>
            <p>
              <ul className="list-disc space-y-4">
                <li>
                  <b>Барьерные методы</b> (презерватив, диафрагма, колпачок,
                  губка). Стоит отметить, что презерватив защищает не только от
                  наступления нежелательной беременности, но и от ЗППП.{" "}
                </li>
                <li>
                  <b>Спермициды</b> (вагинальные свечи, кремы, таблетки,
                  капсулы). Вводятся во влагалище непосредственно перед половым
                  контактом. Минус: химические контрацептивы могут стать
                  причиной раздражения слизистой влагалища.
                </li>
                <li>
                  <b>Внутриматочная спираль.</b> Метод надежный и эффективный
                  (индекс Перля низкий). На сегодняшний день есть и гормональная
                  спираль, которая помимо контрацептивного эффекта, обладает еще
                  и лечебным эффектом.
                </li>
                <li>
                  <b>Экстренная контрацепция</b> (гормональные средства, которые
                  принимаются после незащищенного полового контакта в течение
                  72-96 часов). Могут стать причиной гормонального сбоя. Не
                  предназначены для регулярного использования.
                </li>
                <li>
                  <b>Гормональная контрацепция.</b> Различают микро-,
                  низкодозированные и высокодозированные препараты. Грамотно
                  подобранные гормональные контрацептивы не только помогут
                  предупредить нежелательную беременность, но и решить другие
                  проблемы: скорректировать вес, уменьшить менструальные боли,
                  улучшить состояние кожи лица и головы, помочь в лечении
                  некоторых эстрогенозависимых заболеваний (миома матки,
                  эндометриоз и др.).
                </li>
              </ul>
            </p>
            <p>
              Нередко пары практикуют такие методы предохранения от наступления
              беременности, как: прерванный половой контакт, календарный метод и
              метод лактационной аменореи (период грудного вскармливания). Важно
              осознавать, что эти методы не являются надежными!
            </p>
          </div>
          <div className="basis-1/2 m-auto border-gray-200">
            <img src={medics2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
