export const roles = [
  {
    value: "SUPERADMIN",
    text: "СУПЕР АДМИНСТРАТОР",
  },
  {
    value: "ADMIN",
    text: "АДМИНСТРАТОР",
  },
  {
    value: "DOCTOR",
    text: "ДОКТОР",
  },
  {
    value: "NURSE",
    text: "МЕДСЕСТРА",
  },
  { value: "PATIENT", text: "Пациент" },
];

export const maritalStatus = [
  { value: "single", text: "не замужем" },
  { value: "married", text: "замужем" },
  { value: "divorced", text: "разведена" },
  { value: "widowed", text: "вдова" },
];

export const education = [
  { value: "noeducation", text: "Не имеется" },
  { value: "school", text: "Средняя (11 классов)" },
  { value: "collage", text: "Средняя (Специализированная)" },
  { value: "bachalors", text: "Высшая (Бакалавр)" },
  { value: "masters", text: "Высшая (Магистр)" },
  { value: "PHD", text: "Доктор/Кандидат наук" },
];
